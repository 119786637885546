<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">Lịch sử</span>
      <v-spacer></v-spacer>
      <v-btn
        small
        color="success"
        outlined
        @click="showNoteDialog"
        class="mr-2"
      >
        {{ $t("labels.note") }}
      </v-btn>
      <v-btn small color="error" outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="">{{ $t("labels.employee") }}</th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.classify") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.note") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.time") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in items" :key="`d_${key}`">
              <td>{{ item.employee_name }}</td>
              <td class="text-center">
                <template v-if="[0, 1].includes(item.type)">
                  <span class="fs-16">{{ item.quantity }}</span>
                  <span class="grey--text" v-if="item.type"
                    >/{{ item.total_finded_quantity }}</span
                  >
                </template>
              </td>
              <td class="text-center">
                {{ $t(`labels.find_goods_history_type_${item.type}`) }}
              </td>
              <td class="text-center">{{ item.note }}</td>
              <td class="text-center">{{ formatDateTime(item.created_at) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog v-model="noteDialog" persistent max-width="400px">
      <FindGoodsNote
        v-if="noteDialog"
        :item="item"
        @closeDialog="hideNoteDialog"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
export default {
  name: "FindGoodsHistory",
  components: {
    FindGoodsNote: () => import("@/components/pos_goods/FindGoodsNote"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    noteDialog: false,
  }),
  mounted() {
    this.getDetails();
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    async getDetails() {
      const { data } = await httpClient.post("/find-goods-manager-history", {
        id_find_goods: this.item.id,
      });
      this.items = [...data];
    },
    showNoteDialog() {
      this.noteDialog = true;
    },
    hideNoteDialog() {
      this.noteDialog = false;
      this.getDetails();
    },
  },
};
</script>

<style scoped></style>
